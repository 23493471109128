<template>
  <div
    v-if="productIntroData"
    class="product-intro"
  >
    <ProductIntroGalleryEnter>
      <template
        v-if="pageStructureAbtStyleInfo.isNew"
        #underGallery
      >
        <!-- 评论区布局优化 -->
        <CustomerReviews class="sticky-under-gallery" />

        <template
          v-if="cStoreSeriesBrandInfo.isShowStoreOnLeft && storeDataReady"
        >
          <!-- 品牌集成店 单独展示店铺 -->
          <template v-if="cStoreSeriesBrandInfo.showStoreBrandOnlyStore">
            <EntryBox
              v-if="storeDataReady"
              :report-immediately="storeReportImmediately"
              :title="language.SHEIN_KEY_PC_21175"
              v-bind="cStoreSeriesBrandInfo.componentProps"
              disabled-fold
            >
              <template #content>
                <StoreEntry v-bind="cStoreSeriesBrandInfo.componentProps" />
              </template>
            </EntryBox>
          </template>
          <ProductIntroBrandSeriesDetail
            v-else
            v-bind="cStoreSeriesBrandInfo.componentProps"
            disabled-fold
          />

          <ProductIntroMatchingStyles
            v-if="!IS_RW && isShowMatchingStyles && detailFamilyOutfits"
            disabled-fold
          />
        </template>
      </template>
    </ProductIntroGalleryEnter>
    <!-- ProductInfo -->
    <div class="product-intro__info">
      <div class="product-intro__info-sticky">
        <ProductIntroHead @switch-color="switchColor" />

        <div class="product-intro__select-box">
          <ProductIntroCatSelectionTag
            v-if="showCatSelectionTag"
            :css-right="GB_cssRight"
            :preferred-data="productIntroData.cccCatSelectionTag"
            :language="language"
            :quality-floor="qualityFloor"
          />
          <ProductIntroColorV2
            class="product-intro__color"
            :product-intro-data="productIntroData"
            :language="language"
            :page-components="pageComponents"
            :is-detail="true"
            :load-conf="loadConf"
            :goods-desc="goodsDesc"
            :cdn-url="PUBLIC_CDN"
            :web-client="WEB_CLIENT"
            :fixed-ratio="fixedRatio || '3-4'"
            :is-support-crop-image="RESOURCE_SDK?.isSupportCropImage"
            :show-attr-img-popover="showAttrImgPopover"
            @switch-color="color => switchColor(color, true)"
            @current-selected-color="handleCurrentSelectedColor"
            @handle-data="handleData"
          />
          <ProductIntroSizeV4
            v-model:current-unit="currentSizeUnit"
            :country="localCountry"
            @open-quick-view="openQuickView"
            @switch-color="switchColor"
            @show-login-modal="openLoginModal"
          />
          <!-- <ProductIntroSizeV3
            v-model:current-unit="currentSizeUnit"
            :from="'detail'"
            :site-uid="SiteUID"
            :show-quick-ship="false"
            :event-click-id="'1-8-6-5'"
            :product-intro-data="productIntroData"
            :new-flash-promotion="newFlashPromotion"
            :language="language"
            :ccc-config="cccConfig"
            :country="country"
            :check-not-selected-tips="checkNotSelectedTips"
            :page-components="pageComponents"
            :show-recommend="true"
            :external-sku-code="externalSkuCode"
            :show-heel-height="showHeelHeight"
            :heel-height="heelHeight"
            :cm-inch-info="cmInchInfo"
            :related-good-sizes="relatedGoodSizes"
            :is-new-size-local="isNewSizeLocal"
            :is-show-size-tips="isShowSizeTips"
            :show-size-feed-back="showSizeFeedBack"
            :lang-path="langPath"
            :is-rw="IS_RW"
            :css-right="GB_cssRight"
            :config="{
              showHightSize: isShowHeightSize,
              showTaxation: showTaxation,
              hideOneSize: hideOneSize,
            }"
            :find-similar-float-info="findSimilarFloatInfo"
            @handle-change-local-size="handleChangeLocalSize"
            @handle-data="handleData"
            @compose="compose"
            @show-login-modal="openLoginModal"
            @open-quick-view="openQuickView"
            @mall-click="mallClick"
            @handle-click-size-group-item="handleClickSizeGroupItem"
            @update-quick-ship="handleUpdateQuickShip"
            @set-good-detail-from="setGoodDetailFrom"
          /> -->
          <ProductIntroQty v-if="showQty" />

          <ProductIntroQuickShip
            v-if="showQuickShip"
            from="detail"
            :mall-code="productIntroData.mallCode"
            :sku-info="skuInfo"
            :sku-list="saleAttrList.sku_list"
            :skc-sale-attr="noCalSkcSaleAttr"
            :local-size-map="localSizeMap"
            :language="language"
            :delivery-floor-quickship-title="deliveryFloorQuickshipTitle"
            :is-new-size-local="isNewSizeLocal"
            @update-quick-ship="handleUpdateQuickShip"
          />
        </div>

        <!-- TODO 按钮对照 ⬇️ -->
        <!-- <ProductIntroAdd /> -->
        <!-- TODO 按钮对照 ⬆️ -->

        <ProductIntroBtnEntry />
        <ProductFindSimilarTips />

        <ProductIntroParallelimport v-if="showParallelimport" />
        <!-- <ProductSheinXEnter 
          v-if="isShowSheinX"
          :designer-info="productIntroData.designerInfo"
          :position-style="`single`"
        /> -->
        <ClientOnly>
          <BuyBox 
            v-if="showBuyBox"
          />
        </ClientOnly>

        <!-- 新品牌集成店模块 -->
        <ProductIntroBrandSeriesDetail
          v-if="cStoreSeriesBrandInfo.showNewStoreBrand"
          v-bind="cStoreSeriesBrandInfo.componentBrandProps"
          :extend-collapse-type="`brand_zone`"
          style="margin-top: 27px"
        />

        <ProductIntroShippingEnter
          v-if="!isAllSoldOut"
          :class="{
            'hide-margin-top': cStoreSeriesBrandInfo.showNewStoreBrand,
          }"
        />

        <div class="product-intro__accordion j-expose__accordion">
          <ProductIntroMaterial v-if="showMaterial" />
          <ProductIntroDetailFPos
            v-if="pageComponents.descPosConf.fPosData.isShow"
          />
          <ProductIntroDetailOPos
            v-if="pageComponents.descPosConf.fPosData.isShow"
          />
          <!-- 品牌集成店 单独展示品牌 -->
          <ProductIntroBrandSeriesDetail
            v-if="cStoreSeriesBrandInfo.showStoreBrand"
            v-bind="cStoreSeriesBrandInfo.componentBrandProps"
          />
          
          <!-- 用户说明书 pdf  -->
          <ClientOnly>
            <ProductIntroUserManual
              v-if="showUserManualPdf"
              :instruction-url="instructionUrl"
            />
          </ClientOnly>
         
          <ProductIntroDescription
            v-if="!pageComponents.descPosConf.hiddenDescriptionModule"
          />
          <ClientOnly>
            <ProductIntroWarn
              :language="language"
              :show-eu-resp-person="showEuRespPerson"
            />
          </ClientOnly>
          <ProductIntroSizeGuide
            v-if="pageComponents.sizeGuideConf.isShow"
            v-model:current-unit="currentSizeUnit"
          />
          <!-- 品牌集成店 单独展示店铺 -->
          <template
            v-if="
              cStoreSeriesBrandInfo.showStoreBrandOnlyStore &&
                !cStoreSeriesBrandInfo.isShowStoreOnLeft
            "
          >
            <EntryBox
              v-if="storeDataReady"
              :report-immediately="storeReportImmediately"
              :title="language.SHEIN_KEY_PC_21175"
              v-bind="cStoreSeriesBrandInfo.componentProps"
            >
              <template #content>
                <StoreEntry v-bind="cStoreSeriesBrandInfo.componentProps" />
              </template>
            </EntryBox>
          </template>
          <ProductIntroCottonSource v-if="showCottonSource" />
          <ProductIntroIngredients />

          <ClientOnly>
            <!-- 品牌系列 或 店铺 banner入口 -->
            <ProductIntroBrandSeriesDetail
              v-if="
                cStoreSeriesBrandInfo.showStoreSeriesBrand &&
                  !cStoreSeriesBrandInfo.isShowStoreOnLeft
              "
              v-bind="cStoreSeriesBrandInfo.componentProps"
            />
            <ProductIntroOtherOptions
              v-if="IS_RW || (isShowMatchingStyles && !detailFamilyOutfits)"
            />
          </ClientOnly>
          <ProductIntroMatchingStyles
            v-if="
              !IS_RW &&
                isShowMatchingStyles &&
                detailFamilyOutfits &&
                !cStoreSeriesBrandInfo.isShowStoreOnLeft
            "
          />
        </div>
      </div>
    </div>
    <client-only>
      <!-- OUTFIT DIALOG -->
      <ProductIntroOutfitDialog
        v-if="renderOutfitDialog"
        v-model:value="showOutfit"
        :outfit-data="outfitData"
      />
      <OneClickPay
        :should-auth="oneClickPayAuth"
        :goods-data="productIntroData.detail"
        :current-mall-code="productIntroData.mallCode"
        :bill-no="currentOCPBillNo"
        :stage="oneClickPayState.stage"
        @verify-done="oneClickPayVerifyDone"
      />
    </client-only>
  </div>
</template>

<script>
import ProductIntroGalleryEnter from './ProductIntroGalleryEnter'
import ProductIntroHead from './ProductIntroHead'
// import ProductIntroColorV2 from './Color_backup'
import ProductIntroColorV2 from './Color'
// import ProductIntroSizeV3 from './ProductIntroSize_v3'
import ProductIntroSizeV4 from './ProductIntroSize_V4'
import ProductIntroQty from './ProductIntroQty'
import ProductIntroQuickShip from './ProductIntroQuickShip'
import ProductIntroMaterial from './ProductIntroMaterial'
import ProductIntroDetailFPos from './ProductIntroDetailFPos'
import ProductIntroDetailOPos from './ProductIntroDetailOPos'
import ProductIntroDescription from './ProductIntroDescription'
import ProductIntroWarn from './ProductIntroWarn.vue'
import ProductIntroCottonSource from './ProductIntroCottonSource'
import ProductIntroIngredients from './ProductIntroIngredients'
import ProductIntroSizeGuide from './ProductIntroSizeGuide'
import ProductIntroBrandSeriesDetail from './ProductIntroBrandSeriesDetail'
import ProductIntroOtherOptions from './ProductIntroOtherOptions'
import ProductIntroMatchingStyles from './ProductIntroMatchingStyles'
import ProductIntroShippingEnter from './ProductIntroShippingEnter/ShippingMain'
import ProductIntroParallelimport from './ProductIntroParallelimport'
import ProductIntroCatSelectionTag from './ProductIntroCatSelectionTag'
import EntryBox from './ShopEntry/EntryBox'
import StoreEntry from './ShopEntry/StoreEntry'
import {
  parseQueryString,
  getQueryString,
  template,
} from '@shein/common-function'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { defineAsyncComponent } from 'vue'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { getFitPercentageRoundNum } from '../utils/sizeInfoDesc.js'
import { abtservice } from 'public/src/services/abt'
import { getGoodsUrl } from 'public/src/pages/common/utils/index.js'

import { cacheRecentViewGoodsData } from '@detail/utils/cacheGoodsData.js'
// SSR
let QuickViewPopover = {}
if (typeof window !== 'undefined') {
  import('public/src/pages/common/quickView').then(res => {
    QuickViewPopover = res.default
  })
}

export default {
  name: 'ProductIntro',
  components: {
    ProductIntroParallelimport,
    ProductIntroGalleryEnter,
    ProductIntroHead,
    ProductIntroColorV2,
    // ProductIntroSizeV3,
    ProductIntroSizeV4,
    ProductIntroQty,
    ProductIntroQuickShip,
    ProductIntroMaterial,
    ProductIntroDetailFPos,
    ProductIntroDetailOPos,
    ProductIntroDescription,
    ProductIntroWarn,
    ProductIntroCottonSource,
    ProductIntroIngredients,
    ProductIntroSizeGuide,
    ProductIntroBrandSeriesDetail,
    ProductIntroOtherOptions,
    ProductIntroMatchingStyles,
    ProductIntroShippingEnter,
    ProductIntroCatSelectionTag,
    ProductIntroUserManual: defineAsyncComponent(() =>
      import('./ProductIntroUserManual.vue')
    ),
    EntryBox,
    StoreEntry,
    ProductFindSimilarTips: defineAsyncComponent(() =>
      import('./ProductFindSrimilarFloat/ProductFindSimilarTips.vue')
    ),
    // ProductIntroSummaryFixed: defineAsyncComponent(() => import('./ProductIntroSummaryFixed.vue')),
    ProductIntroOutfitDialog: defineAsyncComponent(() =>
      import('./OutfitRecommend/OutfitDialog.vue')
    ),
    OneClickPay: defineAsyncComponent(() => import('./OneClickPay/index.vue')),
    ProductIntroBtnEntry: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "product-intro-add" */ './ProductIntroBtnBox/ProductIntroBtnEntry.vue'
      )
    ),
    // ProductIntroAdd: defineAsyncComponent(() => import(/* webpackChunkName: "product-intro-add" */'./ProductIntroAdd.vue')),
    ClientOnly,
    CustomerReviews: defineAsyncComponent(() =>
      import('./CustomerReviews.vue')
    ),
    BuyBox: defineAsyncComponent(() => import('./BuyBox/BuyBox.vue')),
  },
  data() {
    return {
      skuUnWatch: () => {},
      mallUnWatch: () => {},
      currentSizeUnit: -1,
      // 当前一键购订单号
      currentOCPBillNo: '',
      isShowMatchingStyles: false,
      detailFamilyOutfits: false,
    }
  },
  computed: {
    ...mapState('AddBtn', ['checkNotSelectedTips', 'oneClickPayState']),
    ...mapState([
      'currentGoodsId',
      'isAfterSsr',
      // 'findSimilarFloatInfo',
      'productIntroData',
      'goodSn',
      'unfold',
      'noResetUnfold',
      'otherOptions',
      'language',
      'cccConfig',
      // 'checkNotSelectedTips',
      'country',
      'ssrLocalCountry',
      'externalSkuCode',
      'currentSelectedColor',
      'showHeelHeight',
      'heelHeight',
      'cmInchInfo',
      'skuInfo',
      'saleAttrList',
      'realtimePricePromotionStatus',
      'renderOutfitDialog',
      'showOutfit',
      // 'addtobagkeepAbt',
      'WEB_CLIENT',
      'PUBLIC_CDN',
      'IS_RW',
      'SiteUID',
      'langPath',
      'GB_cssRight',
      'storeAndBrandDataReady',
      'reviewBaseDataReady',
      'serverTime',
      'brandSeriesDetail',
      'fixedRatio',
      'RESOURCE_SDK',
      'skcEstimatedInfo'
    ]),
    ...mapState('SimilarFloat', ['findSimilarFloatInfo']),
    ...mapGetters([
      'isAllSoldOut',
      'pageComponents',
      'accordionDefaultUnfold',
      'detailSignBoardData',
      'showSizeFeedBack',
      'screenAbtConfig',
      'storeFollowExtAnalysisInfo',
      'hideOneSize',
      'isBrandHasTargetTag',
      'isAbtShowNewStoreBrand',
      'brandCollectionAdjustNew',
      'showQuickShip',
      'deliveryFloorQuickshipTitle',
      'pageStructureAbtStyleInfo',
      'newFlashPromotion',
      'getEstimatedInfo',
      'showBuyBox'
    ]),
    localSizeMap() {
      const { multiLocalSize } = this.productIntroData || {}
      const { size_rule_list } = multiLocalSize || {}
      const result = {}
      size_rule_list?.[this.localCountry]?.forEach(item => {
        if (item.name == 'one-size') return
        result[item.name] = item.correspond
      })
      return result
    },
    localCountry() {
      // TODO: 兼容旧数据流格式,后续数据流重构后删除ssrLocalCountry, country服务端指出
      if (!this.isAfterSsr) {
        return this.country || this.ssrLocalCountry
      }
      return this.country
    },
    showAttrImgPopover() {
      return (
        this.screenAbtConfig?.goodsMainAttPicShow?.param
          ?.goodsMainAttPicShow === 'on'
      )
    },
    showParallelimport() {
      return !!(
        this.screenAbtConfig?.parallelimport?.param?.parallelimport ===
          'show' &&
        this.productIntroData?.sellerLabelInfo?.find?.(
          item => item?.labelCode === 'international_imported_products'
        )
      )
    },
    hasOtherOptions() {
      return this.productIntroData?.getOtherOptions?.length
    },
    showSizeFeedBack() {
      const { productIntroData } = this
      return this.getTargetCatIdStatus([productIntroData?.parentCats || {}])
    },
    isNewSizeLocal() {
      return true
    },
    sizeInfoDes() {
      return this.productIntroData?.sizeInfoDes || {}
    },
    relatedGoodSizes() {
      let relatedGoods = this.productIntroData.getOtherOptions || []
      return relatedGoods?.[0] ?? {}
    },
    outfitData() {
      return this.productIntroData?.outfitsImg || {}
    },
    loadConf() {
      if (
        this.IS_RW ||
        this.pageComponents.productIntroGalleryConf.isRwGallery
      ) {
        return {
          size: '2',
          num: 1,
        }
      } else {
        return {
          size: '1',
          num: 0,
        }
      }
    },
    goodsDesc() {
      return this.pageComponents?.descriptionConf?.goodsDesc || ''
    },
    oneClickPayAuth() {
      const { productIntroData } = this
      return productIntroData?.oneClickPayAuth || false
    },
    isShowSizeTips() {
      let showTrueToSizeStrongText = ''
      const {
        apolloConfig = {},
        commentInfo = {},
        parentCats = {},
      } = this.productIntroData
      const { trueToSizeStrongCatId = [] } = apolloConfig?.sizeTipsConfig || {}
      const showTrueToSizeStrongAbt =
        this.screenAbtConfig?.truetosizetips?.param?.truetosizetips === 'A'
      const true_size = commentInfo?.percent_overall_fit?.true_size || ''
      const comment_num = commentInfo?.comment_num || 0
      const hasSizeAttr = (this.saleAttrList?.skc_sale_attr || []).find(
        _ => _.attr_id == 87
      )
      const true_size_num = getFitPercentageRoundNum(true_size)
      if (
        showTrueToSizeStrongAbt &&
        hasSizeAttr &&
        true_size_num >= 90 &&
        comment_num >= 10 &&
        this.checkCatId([parentCats], trueToSizeStrongCatId)
      ) {
        const lanText = this.language.SHEIN_KEY_PC_28172
        showTrueToSizeStrongText = template('', lanText)
      }
      const defaultData = {
        // 是否命中实验组
        // isHitExperiment: this.abtSupportSizeTips,
        // 是否大码提示
        isLargeSizeTips: this.isLargeSizeTips,
        // 提示文案
        sizeTipsText: this.isLargeSizeTips
          ? this.language.SHEIN_KEY_PC_25123
          : this.language.SHEIN_KEY_PC_25124,
        // true to size 强化文案
        showTrueToSizeStrongText,
        true_size_text: true_size,
        showTrueToSizeStrong: !!showTrueToSizeStrongText,
        // 是否展示
        isShow: false,
      }

      // 1. 是否目标品类id
      if (!this.isHitCatId()) {
        return defaultData
      }

      // 2. 是否异常尺寸
      if (!this.isExceptionSize()) {
        return defaultData
      }

      return {
        ...defaultData,
        isShow: true,
      }
    },
    isLargeSizeTips() {
      const { max, large } = this.getSizeTipsData()
      return large >= max
    },
    catIds() {
      const { catIds = [] } =
        this.productIntroData?.apolloConfig?.sizeTipsConfig || {}
      return catIds
    },

    showTaxation() {
      const { showTaxation } =
        this.productIntroData?.apolloConfig?.sizeTipsConfig || {}
      return !!showTaxation
    },
    // 因为 ssr cpu 压力过高，复杂计算交给客户端 下面拿的是未计算的数据
    noCalAttrList() {
      const { detail = {}, attrSizeList = {} } = this.productIntroData
      const { sale_attr_list } = attrSizeList
      const goodsId = detail?.goods_id
      return sale_attr_list[goodsId]
    },
    noCalSkcSaleAttr() {
      return this.noCalAttrList?.skc_sale_attr || []
    },
    showMaterial() {
      const { pageComponents } = this
      return (
        pageComponents.materialConf.isShow &&
        pageComponents.materialConf?.materialList?.length &&
        pageComponents.descPosConf.fPosData.isShow === false
      )
    },
    isShowHeightSize() {
      const { heightCatIds = [] } =
        this.productIntroData?.apolloConfig?.sizeTipsConfig || {}
      return this.checkCatId([this.productIntroData?.parentCats], heightCatIds)
    },
    isShowNewStoreBrand() {
      if (this.isAbtShowNewStoreBrand && this.isBrandHasTargetTag) {
        return !!this.brandSeriesDetail?.type
      }
      return (
        this.isAbtShowNewStoreBrand &&
        this.isStoreBrand &&
        this.signData?.brandData?.type
      )
    },
    isShowStoreBrand() {
      if (this.isShowNewStoreBrand) return false
      const _hasData = !!this.signData?.brandData?.type
      if (!_hasData) return false
      return this.showStoreAndBrand && (!this.isAbtShowNewStoreBrand || this.brandCollectionAdjustNew) || (this.isStoreBrand &&  !this.isAbtShowNewStoreBrand)
    },
    isStoreBrand() {
      const { storeInfo = {} } = this.productIntroData
      return storeInfo.storeBusinessType == 2
    },
    showStoreAndBrand() {
      // 同时存在店铺 & 品牌
      const { showStoreAndBrand } = this.detailSignBoardData
      return showStoreAndBrand
    },
    showCatSelectionTag() {
      return this.productIntroData?.cccCatSelectionTag?.descKeyList?.[0]
        ?.langKeyEn
    },
    qualityFloor() {
      return this.screenAbtConfig?.Qualityfloor?.p?.Qualityfloor
    },
    showCottonSource() {
      const { cotton } = this.screenAbtConfig
      return cotton?.p == 'show' && !!this.productIntroData.skcSource
    },
    // 店铺/品牌区数据是否已准备好
    // TODO-staging 由于店铺评分数据来源于评论接口，需要等待评论数据加载完毕
    // TODO-staging 后续在后端提供接口支持后，单独拉取店铺评分（由tsp，减少消耗）
    storeDataReady() {
      const { storeAndBrandDataReady, reviewBaseDataReady } = this
      return storeAndBrandDataReady && reviewBaseDataReady
    },
    signData() {
      const {
        isStoreBrand,
        detailSignBoardData,
        isAbtShowNewStoreBrand,
        isBrandHasTargetTag,
      } = this
      const { showStore, storeData, brandData } = detailSignBoardData
      const _hasBrandData = brandData && !!brandData?.type
      if (
        isStoreBrand ||
        this.showStoreAndBrand ||
        /* 新品牌模块 */ (_hasBrandData &&
          isAbtShowNewStoreBrand &&
          isBrandHasTargetTag)
      ) {
        return detailSignBoardData
      } else if (showStore) {
        return storeData
      } else {
        return brandData
      }
    },
    productIntroDataGoodsId() {
      return this.productIntroData?.detail?.goods_id || ''
    },
    /**
     * 店铺系列品牌数据
     */
    cStoreSeriesBrandInfo() {
      const {
        signData,
        showStoreAndBrand,
        isShowNewStoreBrand,
        isShowStoreBrand,
        isStoreBrand,
        storeFollowExtAnalysisInfo,

        pageStructureAbtStyleInfo,
      } = this

      const res = {
        /**
         * 品牌集成店 单独展示品牌
         */
        showStoreBrand: false,
        /**
         * 品牌集成店 单独展示店铺
         */
        showStoreBrandOnlyStore: false,
        /**
         * 新品牌集成店模块
         */
        showNewStoreBrand: false,
        /**
         * 品牌系列 或 店铺 banner入口
         */
        showStoreSeriesBrand: false,
        /**
         * 展示组件依赖的Props
         */
        componentProps: null,
        /**
         * 展示品牌集成店组件依赖的Props
         */
        componentBrandProps: null,
        /**
         * 是否属于门店类型
         */
        isStore: false,
        /**
         * 是否展示品牌
         */
        hasBrand: false,
        /**
         * 店铺显示在左侧
         */
        isShowStoreOnLeft: false,
      }

      if (!signData) return res

      /**
       * 新品牌集成店
       * 互斥
       * 品牌集成店 单独展示品牌
       */
      if (isShowNewStoreBrand) {
        res.showNewStoreBrand = true

        // 新品牌集成店视为品牌
        res.hasBrand = true

        res.componentBrandProps = {
          brandSeriesDetail: signData.brandData
            ? signData.brandData
            : this.brandSeriesDetail,
          isStoreBrand: true,
          isAbtShowNewStoreBrand: true,
        }
      } else if (isShowStoreBrand) {
        res.showStoreBrand = true
        res.hasBrand = true

        res.componentBrandProps = {
          brandSeriesDetail: signData.brandData,
          isStoreBrand: true,
        }
      }

      /**
       * 品牌系列 或 店铺 banner入口
       * 互斥
       * 品牌集成店 单独展示店铺
       */
      if (isStoreBrand) {
        res.showStoreBrandOnlyStore = true
        res.hasStore = true
        res.componentProps = {
          storeInfo: signData.storeData,
          isStoreBrand: true,
          relativeInfo: signData.storeData,
          extAnalysisInfo: storeFollowExtAnalysisInfo,
          isShowRecommend: true,
        }
      } else if (!isStoreBrand) {
        res.showStoreSeriesBrand = true
        const commonBrandData = signData?.brandData
          ? signData.brandData
          : signData
        res.componentProps = {
          brandSeriesDetail: showStoreAndBrand
            ? signData.storeData
            : commonBrandData,
          isShowRecommend: true,
        }
        res.hasStore =
          showStoreAndBrand ||
          res.componentProps.brandSeriesDetail?.type === 'store'
      }

      // 必须命中abt且为门店
      res.isShowStoreOnLeft =
        !res.hasStore || !pageStructureAbtStyleInfo.isStoreLeft ? false : true
      if (res.isShowStoreOnLeft) {
        /**
         * 推荐列表尺寸
         */
        res.componentProps = res.componentProps || {}
        res.componentProps.recommendSize = '4_5'
      }

      return res
    },
    instructionUrl() {
      return this.productIntroData?.detail?.instruction_url
    },
    // 是否展示用户说明书
    showUserManualPdf() {
      const userManualAbtShow = this.productIntroData.screenAbtConfig?.usermanual?.param?.usermanual === 'show'
      return !this.IS_RW && this.instructionUrl && userManualAbtShow
    },
    showQty() {
      if (this.skcEstimatedInfo?.isSatisfiedBuyMultiple) return true
      return this.pageComponents.qtyConf.isShow
    },
    storeReportImmediately() {
      let isStore = this.cStoreSeriesBrandInfo.showStoreBrandOnlyStore
      return isStore && this.unfold?.includes('store')
    },
    showEuRespPerson() {
      return this.screenAbtConfig?.goodsdetailscompliance?.param?.R_gpsr_tips === 'on'
    },
  },
  watch: {
    accordionDefaultUnfold: {
      handler(val) {
        if (this.noResetUnfold) return
        this.assignState({ unfold: val })
      },
    },
    productIntroDataGoodsId: {
      immediate: true,
      async handler(newValue, oldValue) {
        if (
          typeof window !== 'undefined' &&
          !this.IS_RW &&
          newValue !== oldValue
        ) {
          // 销毁之前的sku监听事件
          this.skuUnWatch?.()
          this.mallUnWatch?.()
          // 主动或者默认选中sku，等待切换后的sku更新再触发请求
          if (
            this.skuInfo?.sku_code ||
            (!oldValue && getQueryString({ key: 'skucode' }))
          ) {
            await new Promise(resolve => {
              const unWatch = this.$watch('skuInfo', (skuNewVal, skuOldVal) => {
                if (skuNewVal?.sku_code !== skuOldVal?.sku_code) {
                  this.$store.dispatch('updateFindSimilarFloat', {
                    from: !oldValue ? 'pageInit' : 'skcChange',
                  })
                  unWatch()
                  resolve()
                }
              })
            })
          } else {
            this.$store.dispatch('updateFindSimilarFloat', {
              from: !oldValue ? 'pageInit' : 'skcChange',
            })
          }
          // 注册sku变化监听
          this.skuUnWatch = this.$watch('skuInfo', (newVal, oldVal) => {
            if (newVal?.sku_code !== oldVal?.sku_code) {
              // sku无值走skcChange
              this.$store.dispatch('updateFindSimilarFloat', {
                from: newVal?.sku_code ? 'skuChange' : 'skcChange',
              })
            }
          })
          // 注册mall变化监听
          this.mallUnWatch = this.$watch(
            'productIntroData.mallCode',
            (newVal, oldVal) => {
              if (newVal !== oldVal) {
                this.$store.dispatch('updateFindSimilarFloat', {
                  from: 'skuChange',
                })
              }
            }
          )
        }

        setTimeout(() => {
          if (typeof window !== 'undefined') {
            cacheRecentViewGoodsData(this.productIntroData)
          }
        }, 2000)
      },
    },
  },
  mounted() {
    let sizeUnit = localStorage.getItem('selectedUnit')
    this.currentSizeUnit = sizeUnit
      ? Number(sizeUnit)
      : Number(this.productIntroData.sizeInfoDes.sizeUnit)
    this.setDefaultGoodsSize()
    this.getAllSignDetail()
    this.currentOCPBillNo = parseQueryString(location.search)?.billno
    abtservice.useState(
      'DetailFamilyOutfits',
      this.screenAbtConfig?.DetailFamilyOutfits
    )
    // matchingstyles 控制是否展示
    this.isShowMatchingStyles =
      this.screenAbtConfig?.matchingstyles?.param?.matchingstyles !== 'none'
    // DetailFamilyOutfits 控制展示MatchingStyles还是OtherOptions
    this.detailFamilyOutfits =
      !!this.screenAbtConfig?.DetailFamilyOutfits?.param?.DetailFamilyOutfits
  },
  methods: {
    ...mapMutations('AddBtn', [
      'updateCheckNotSelectedTips',
      'updateOneClickPayState',
    ]),
    ...mapMutations(['assignState', 'setProductIntroData']),
    ...mapActions([
      'showLoginModal',
      'setDefaultGoodsSize',
      'fetchStoreInfo',
      'fetchBrandSeriesDetail',
      'getAllSignDetail',
    ]),
    handleData(data) {
      this.assignState(data)
    },
    handleChangeLocalSize(value) {
      this.assignState({ country: value })
    },
    mallClick(mallInfo) {
      const { mallCode, mallStock } = mallInfo
      this.setProductIntroData({ mallCode, mallStock })
    },
    handleUpdateQuickShip(status) {
      this.assignState({ quickShip: status })
    },
    setGoodDetailFrom(val) {
      this.assignState({ getGoodDetailFrom: val })
    },
    compose({ skuInfo, attrs, mallStock }) {
      this.assignState({
        skuInfo,
        skuAttrs: attrs,
      })
      this.setProductIntroData({ mallStock })
    },
    openQuickView(data) {
      // 解决循环引用的问题
      QuickViewPopover.show(data)
    },
    openLoginModal({ addToWish }) {
      QuickViewPopover.hide()
      this.showLoginModal({
        type: 'qv-add-wish',
        originRef: {
          addToWish,
        },
      })
    },
    handleCurrentSelectedColor(color) {
      this.assignState({
        currentSelectedColor: color,
      })
    },
    handleClickSizeGroupItem(item) {
      const color = {
        goods_sn: item.goodsSN,
        goods_id: item.goodsId,
        goods_url_name: item.goodsName,
        cat_id: item.catId,
      }
      this.switchColor(color) // 暂时使用switchColor代替
    },
    switchColor(color, isNewColorComponent = false) {
      let main_attr = ''
      if (isNewColorComponent) {
        const currnetMainSaleAttr = this.currentSelectedColor.mainSaleAttr || {}
        main_attr = currnetMainSaleAttr.attr_value_id
          ? currnetMainSaleAttr.attr_id +
            '_' +
            currnetMainSaleAttr.attr_value_id
          : ''
      } else {
        main_attr = this.currentSelectedColor.attr_value_id
          ? this.currentSelectedColor.attr_id +
            '_' +
            this.currentSelectedColor.attr_value_id
          : ''
      }

      // skc 切换标识
      this.assignState({
        isUpdateSkc: false,
      })
      // 切换颜色重置为初始化状态
      this.updateCheckNotSelectedTips(0)
      this.assignState({
        // checkNotSelectedTips: 0,
        currentSelectedColor: color,
        currentGoodsSn: color.goods_sn,
        attribute: '',
        mainAttribute: main_attr,
        realtimePricePromotionStatus: false, // 处理价格icon闪跳一下又消失的bug
        isUpdateSkc: true,
        outfitData: {
          labels: [],
          url: '',
        },
        outfitActiveId: 0,
      })
      let search = location.search.replace(
        /&?(attr_ids|main_attr|mallCode)=(\d|_)+/g,
        ''
      )
      const mallCode = this.productIntroData.mallCode || ''
      if (main_attr) {
        search =
        search +
        (search.indexOf('?') > -1
          ? '&main_attr=' + main_attr
          : '?main_attr=' + main_attr)
      }
      search =
        search +
        (search.indexOf('?') > -1
          ? '&mallCode=' + mallCode
          : '?mallCode=' + mallCode)
      this.$router.push(
        `${getGoodsUrl(
          color.goods_url_name,
          color.goods_id,
          color.cat_id
        )}${search}`
      )
    },
    oneClickPayVerifyDone({ hasQualification, billInfo }) {
      const { oneClickPayState } = this
      this.updateOneClickPayState({
        ...oneClickPayState,
        support: !!hasQualification,
        billInfo: billInfo || null,
      })
      this.currentOCPBillNo = ''
    },
    isHitCatId() {
      return this.checkCatId([this.productIntroData?.parentCats], this.catIds)
    },
    checkCatId(arr, catIds) {
      // 无catIds配置项时提前返回
      if (!catIds.length) {
        return false
      }

      for (let item of arr) {
        if (catIds.includes(item.cat_id)) {
          return true
        }

        if (item?.children?.length) {
          return this.checkCatId(item?.children, catIds)
        }
      }

      return false
    },
    isExceptionSize() {
      const { max, min, large, small } = this.getSizeTipsData()
      // 存在 146 tips 或 ccc tips 文案时不展示
      if (
        this.productIntroData?.detail?.productDetails?.find?.(
          item => item.attr_id == '146'
        )?.attr_value ||
        this.cccConfig.tips ||
        !this.cccConfig.isReady
      ) {
        return false
      }

      return (large >= max || small >= max) && Math.abs(large - small) >= min
    },
    getSizeTipsData() {
      const { max, min } =
        this.productIntroData?.apolloConfig?.sizeTipsConfig || {}
      const { size_tips: sizeTips = {} } =
        this.productIntroData?.commentInfo || {}
      const { large, small } = this.getOverallFitNum(sizeTips)

      return {
        max,
        min,
        large,
        small,
      }
    },
    getOverallFitNum(overallFit) {
      const { large = '', small = '' } = overallFit || {}
      return {
        large: this.delPercentSign(large),
        small: this.delPercentSign(small),
      }
    },
    delPercentSign(str) {
      return Number(str.replace('%', ''))
    },
    isTarget(cat_id) {
      const { WEB_CLIENT } = this
      return (
        WEB_CLIENT === 'shein' ? ['1888', '2030'] : ['769', '909']
      ).includes(cat_id)
    },
    getTargetCatIdStatus(arr) {
      for (let i = 0; i < arr.length; i++) {
        if (this.isTarget(arr[i]?.cat_id)) return true
        if (arr[i]?.children?.length) {
          const status = this.getTargetCatIdStatus(arr[i]?.children)
          if (status) return true
        }
      }
      return false
    },
  },
}
</script>

<style lang="less">
.product-intro {
  position: relative;
  display: flex; /*//left img*/
  &__galleryWrap {
    width: 730px;
  }
  &__gallery {
    padding-left: 60px;
    position: sticky;
    top: 0;
    height: fit-content;
    display: flex;
    &_active {
      position: relative;
    }
  }
  .gallery-fixed {
    position: absolute;
    left: 0;
  }
  &__thumbs {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 100%;
    padding-right: 3px;
  }
  &__thumbs-inner {
    height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
      background: #fff;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #fff;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba(224, 224, 224, 1);
      }
    }
  }
  &__thumbs-item {
    position: relative;
    margin-bottom: 5px;
    width: 50px;
    // height: 66px;
    overflow: hidden;
    cursor: pointer;
    &_active {
      &::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        border: 1px solid #222;
        box-shadow: 0 0 0 2px #fff inset;
        background: transparent;
        z-index: 999;
      }
    }
  }
  .s-swiper-container.swiper-container-position {
    position: unset;
  }
  &__thumbs-video {
    background-color: #f7f6f7;
    height: 68px;
  }
  &__thumbs-videoFix {
    position: sticky;
    bottom: 0;
  }
  &__thumbs-lastVideo {
    margin-bottom: 0;
  }
  &__main {
    position: relative;
    width: 670px;
    overflow: hidden;
    .common-swiperv2__prev,
    .common-swiperv2__next {
      opacity: 0;
      pointer-events: auto;
    }
    .common-swiperv2__prev {
      left: 15px;
    }
    .common-swiperv2__next {
      right: 15px;
    }
    &:hover {
      .common-swiperv2__prev,
      .common-swiperv2__next {
        opacity: 1;
      }
    }
    .s-swiper-wrapper{
      position: relative;
      display: flex;
    }
    .s-swiper-slide {
      position: relative;
      width: 100%;
      flex: 0 0 auto;
      height: 0;
      &.product-intro__main-itemRw{
        width: 50%;
      }
    }
    .outfit-wrap {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1; /* stylelint-disable-line */
    }
  }
  &__main-loading {
    position: absolute;
    z-index: 1; /* stylelint-disable-line */
    top: 50%;
    left: 50%;
    width: 18%;
    max-width: 140px;
    transition: opacity 0.1s,
      transform 0.25s cubic-bezier(0.17, 0.88, 0.32, 1.28);
    transform: translate3d(-50%, -50%, 1px) scale(0.7);
    .loading-trace {
      stroke-width: 6%;
      fill: none;
      stroke: rgba(0, 0, 0, 0.8);
      stroke-opacity: 0.6;
    }
    .loading-circle {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      fill: none;
      stroke-width: 6%;
      stroke: #fcfcfc;
      stroke-opacity: 1;
      stroke-linecap: round;
      stroke-dasharray: 200;
      stroke-dashoffset: 200;
      animation: rotate 2s linear 0.25s infinite,
        dash 1.5s ease-in-out 0.25s infinite;
    }
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
  &__main-item {
    height: 0;
    .cover-frame {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      z-index: 1;
    }
  }
  &__play {
    .svgicon {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 24px;
      background-color: #fff;
      border-radius: 15px;
      top: 13px;
      width: 24px;
      height: 24px;
      text-align: center;
      text-indent: 1px;
    }
    div {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 10px;
      bottom: 10px;
      font-weight: 700;
    }
  }
  &__video-lazy {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &__interest-img{
    img{
      width: 100%;
      height: 100%;
    }
  }
  .product-black-flag {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: @zindex-hack;
  }
  .product-brand-flag {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: @zindex-hack;
  }
  .product-series-flag {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: @zindex-hack;
  }
  //right info
  &__info {
    width: 420px;
    .margin-l(50px);
    min-height: 893px;
    font-size: 14px;
    color: #222;
  }
  &__info-sticky {
    position: sticky;
    top: 14px;
    z-index: @zindex-hack;
  }
  &__select-box {
    padding-top: 10px;
    border-top: 1px dashed #e5e5e5;
  }
  &__color,
  &__size,
  &__qty {
    margin-top: 15px;
  }
  .fitanalytics__button-text {
    color: @sui_color_link !important; /* stylelint-disable-line declaration-no-important */
    text-decoration: none !important; /* stylelint-disable-line declaration-no-important */
    &:hover {
      text-decoration: underline !important; /* stylelint-disable-line declaration-no-important */
    }
    /* rw:begin */
    color: #ff9999 !important; /* stylelint-disable-line declaration-no-important */
  }
  .fitanalytics__button-text::before {
    content: "";
    display: inline-block;
    margin-top: -2px;
    .margin-r(5px);
    width: 16px;
    height: 16px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAaVBMVEUAAAAAN2wANWoATXYAN2wANWoANWsANmoANmwANmoANWkANmoANmkANmkANWoAN2oANmwANWkANmoANWkANWoAQHAANWkANmsANmoANmoAOGkANmoANmoAN2sANWoAN2wAN2sAOm0ANWlg2SesAAAAInRSTlMAF/QGK6if8SHjv9nHlpWCR9O2++sQj2zDsDTIhGJgU0Ujjg48iQAAAPRJREFUOMuFk+mywjAIhU+TqtfUpqld9HoXlfd/SBcqJBOdfL86U8IBDkCxwbvGmMb5YJFTtRsSNm2FFNsZSjCdTZ7vKGMXJam39IZtLe/5fx5RLfqSP1PhOjr6SPcU4PrXtdJLLw+RlvUmKBdJ0QKW5/OLmG+ZmEXgr3HCeS3sJUWAJ2aeRnqDhyNmWJFZKS9jHBoSDlAOxDRQk34Qs7RqooAesF/C/9JGKjGTIBKOhPE0ZAEOnhRDffXAxW0GihhOvB+aKfCotU5m1lGzWcIfrvs7RzFL7X5Zwj6J3dnCHNOFKa5caWmLa18+nPLplY+3eP43R/pUNKpeTv0AAAAASUVORK5CYII=")
      no-repeat center / 16px 16px;
    vertical-align: middle;
    /* rw:begin */
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAADJklEQVRYR+1XoXJaQRQ9EaUVUFEwUFFMqAGTRBRUFDVgSgxRUfmoqChQ1IApihpQUVTFFAxUgALDi6BzWG6z77197D5q2pnsDJNksnv33HPPPXc52W63W/xD6+QFkKUa/ylD0wmQSADZ3HFqWy6A+S+gWLSed2Po/l4FurmxBjRu6HSA5RK4vbWedwM0GACPj8DlJVAoWIP6NqzWQLsFpNNAo2E96wZoPgO6PSCfB6pVa1DfhuEI+DEGymWgVLKedQPEMO02sFqpslFPrkvONa+BVNJ6yh2QZBqnbFKuXA6o1axguMEdEDvtW19piKBc1ngMjEbA+bn6OCx3QJ4HsNvSGaDxxSE0gH4fmEyAes3ZMtwB6TpyaN8d4l4PmM2c2l0yNAOiXt68Bs7O/EzIBWSITNnW3Z1qgKB/PTwATx7wqRyKYAYknRHUiwjbtQQEFBS0eFoqBTSbFkC0eO9JuepwqDbroJgZP8cCEjCMW6kos0y88rH9zJCYn6kMAiCuSMmQMOES39f27CKysl4rSBQjF7Oo15UW4pqj7GcTMH63q9jnYim5kknF1t5so0VNu9fBiMmZar8rtRdubSnR5yrwIe8HFeFn0aImYmGGmYigTSZHfyKgYPeJmerCFqb40ypq0Q836/OKf7da6lLTTCLY9Uo5eHDORc2y4B37u92MUborztiQ5PhsYekc55kdkGiHmTeunCZ2qFGFJQe7sAMSd44xIEOApOXZEHykHXi+HAYkoty1fwZ4nwXepZW3BAzNB4KsUlN8P9FGfk4AdiJXsQRUwiNDzkcD0oXM9hf/sM2vqP8zic1GNcaB0kUDEleWUjEQs5zNnzOXy8kEL8toA5eGRxD88NsKX4vC+IEnjBmQbvMXF8Bp4Tgx62wxIb6NOA34e8TL0wxIH4ISlEIUBsT25X/ZrPqNZeVlssjoxgMWeyfXAUawZAakOy9LNJ2ag8bRE5NgQ3w8BQbfFXiDyYYBMavOV7ORiY4IZBFgIwgut2ct+TZc7gNfGKIBOX6PikPSn72iUcMd5pKRJZcn6lFo9oci7rA79d9cesTZF0A20n4DtCHxpADxzRkAAAAASUVORK5CYII=")
      no-repeat center / 16px 16px;
  }
  &__accordion {
    margin-top: 25px;
  }
  &__sizeguide-head,
  &__description-head,
  &__brand-head,
  &__ingredients-head,
  &__aboutbrand-head,
  &__cottonSource-head, 
  &__usermanual-head {
    position: relative;
    font-size: 16px;
    height: 47px;
    line-height: 47px;
    font-weight: 700;
    text-transform: capitalize;
    cursor: pointer;
    /* rw:begin */
    font-family: "Adieu";
    font-weight: normal;

    &.disabled-fold {
      cursor: auto;
    }
    .head-icon {
      position: absolute;
      .right(0);
      font-size: 16px;
      top: 50%;
      transform: translateY(-50%);
      font-weight: normal;
    }
  }
  &__usermanual-head {
    .text-overflow();
    padding-right: 20px;
  }

  &__description-panel {
    padding: 15px 0;
    overflow: hidden;
  }
  &__description-panel_more {
    color: #1860a7;
    font-size: 12px;
    margin: 10px 0 15px;
    cursor: pointer;
  }
  &__description-text {
    padding: 0 0 20px;
    word-break: break-word;
    font-size: 12px;
    line-height: 16px;
  }
  &__description-color {
    display: flex;
    font-size: 12px;
    padding: 0 0 20px;
    > div {
      display: inline-block;
      line-height: 20px;
    }
    .key {
      .padding-r(4px);
      vertical-align: top;
    }
  }
  &__description-table {
    display: table;
    font-size: 12px;
    padding: 0;
  }
  &__description-table-item {
    display: table-row;
    line-height: 20px;
    color: #222;
    > div {
      display: table-cell;
      vertical-align: bottom;
    }
    .key {
      width: 50%;
      .padding-r(25px);
    }
  }
  &__description-table-html {
    word-break: break-word;
    max-height: 40px;
    overflow: hidden;
  }
  &__description-more {
    color: #1860a7;
    cursor: pointer;
  }
  &__freeshipping-more {
    cursor: pointer;
  }
  .hide-margin-top {
    margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (max-width: 1440px) {
  .product-intro {
    &__gallery, &__galleryWrap{
      width: 623px;
    }
    &__main {
      width: 563px;
    }
  }
}
@media (max-width: 1366px) {
  .product-intro {
    &__gallery, &__galleryWrap{
      width: 524px;
    }
    &__main {
      width: 464px;
    }
    &__info {
      min-height: 618px;
    }
  }
}
@media (max-width: 1150px) {
  .product-intro {
    &__thumbImg {
      width: 60.4px;
    }
  }
}
@media (max-width: 1074px) {
  .product-intro {
    &__galleryWrap {
      width: 48.7%;
    }
    &__gallery {
      .padding-l(50px);
      width: 100%;
    }
    &__main {
      width: 100%;
    }
    &__thumbs {
      width: 50px;
    }
    &__thumbs-item {
      width: 40px;
      // height: 40px;
    }
    &__info {
      width: 47.8%;
      .margin-l(3.5%);
    }
    &__thumbImg {
      width: 48.3px;
    }
    &__thumbnail {
      height: 84px;
    }
    &__play {
      .svgicon {
        width: 20px;
        height: 20px;
        font-size: 20px;
      }
    }
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .product-intro__size-radio {
    &:not(.product-intro__size-radio_active) {
      &:hover {
        border: 1px solid #e5e5e5;
        &::before {
          content: none;
        }
      }
    }
    &_disabled {
      border: 1px dashed #e5e5e5;
      &:hover {
        border: 1px dashed #e5e5e5;
      }
    }
  }
}
</style>
