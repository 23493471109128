<template>
  <div class="productintro-wraning">
    <!-- GPSR欧盟责任人 -->
    <div
      v-if="showEuRespPerson"
      class="productintro-wraning__tips"
      :class="{ 'pointer': euRespPerson.isClick }"
      @click="handleDialogOpen"
    >
      <p v-if="euRespPerson.isClick">
        {{ euRespPerson.euRespTitle }}
      </p>
      <p 
        v-else
        v-html="euRespTitleHtml"
      >
      </p>
      <sui_icon_more_right_14px_1
        v-if="euRespPerson.isClick"
        class=""
        size="14px"
        :is-rotate="GB_cssRight"
      />
    </div>
    <!-- detail弹窗 -->
    <SDialog
      v-model:visible="show"
      append-to-body
      :show-close="true"
    >
      <template #title> 
        {{ dialogInfo.popUpTitle }} 
      </template>
      <div class="productintro-wraning__dialog">
        <div class="productintro-wraning__dialog-tit">
          {{ dialogInfo.tipsTitle }}
        </div>
        <div class="productintro-wraning__dialog-item">
          {{ dialogInfo.tipsText }}
        </div>
        <div 
          v-if="dialogInfo.nameValue" 
          class="productintro-wraning__dialog-item"
        >
          <span class="key">{{ dialogInfo.nameKey }}:</span>
          <span class="value">{{ dialogInfo.nameValue }}</span>
        </div>
        <div 
          v-if="dialogInfo.addressValue" 
          class="productintro-wraning__dialog-item"
        >
          <span class="key">{{ dialogInfo.addressKey }}:</span>
          <span class="value">{{ dialogInfo.addressValue }}</span>
        </div>
        <div 
          v-if="dialogInfo.telephoneNumberValue" 
          class="productintro-wraning__dialog-item"
        >
          <span class="key">{{ dialogInfo.telephoneNumberKey }}:</span>
          <span class="value">{{ dialogInfo.telephoneNumberValue }}</span>
        </div>
        <div 
          v-if="dialogInfo.emailAddressValue" 
          class="productintro-wraning__dialog-item"
        >
          <span class="key">{{ dialogInfo.emailAddressKey }}:</span>
          <span class="value">{{ dialogInfo.emailAddressValue }}</span>
        </div>
        <div 
          v-if="dialogInfo.customerServiceValue" 
          class="productintro-wraning__dialog-item"
          @click="openCustomerService(dialogInfo.customerServiceValue)"
        >
          <span class="key">{{ dialogInfo.customerServiceKey }}:</span>
          <span class="value customer_service">{{ dialogInfo.customerServiceValue }}</span>
        </div>
      </div>
    </SDialog>
  </div>
</template>

<script setup>
import { ref, toRefs, computed } from 'vue'
import { SDialog } from '@shein-aidc/sui-dialog'
import { template } from '@shein/common-function'
import { useMapState } from 'public/src/pages/goods_detail_v2/compositions/vuex.composition.js'
import { sui_icon_more_right_14px_1 } from '@shein-aidc/icon-vue3'
defineProps({
  language: {
    type: Object,
    default: () => ({})
  },
  showEuRespPerson: {
    type: Boolean,
    default: false
  },
})

const { 
  productIntroData,
} = useMapState([
  'productIntroData',
])

const {
  euRespPerson,
} = toRefs(productIntroData?.value?.productMiscInfo)

const euRespTitleHtml = computed(() => template(`<a style='color:#5a29bb;cursor:pointer' target='_blank' href='${euRespPerson.value.specialClickUrl}'>${euRespPerson.value.highLightText}</a>`, euRespPerson.value.specialEuRespTitle))


const openCustomerService = (url) => {
  window.open(url)
}
const dialogInfo = computed(() =>  euRespPerson.value.dialogInfo)

const show = ref(false)
const handleDialogOpen = () => {
  if(!euRespPerson.value?.isClick) return
  show.value = true
}

</script>

<style lang="less">
.ellipsis(@clamp: 2) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: @clamp;
}
.productintro-wraning {
  font-size: 12px;
  color: #222;
  &__tips {
    color: #666;
    padding-top: 5px;
    display: flex;
    align-items: center;
    gap: 4px;
    p {
      .ellipsis(3)
    }
  }
  .pointer{
    cursor: pointer;
  }
  &__dialog {
    margin-top: 16px;
    color: #000;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
  }
  &__dialog-tit {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
  &__dialog-item {
    margin-top: 12px;
    word-break: break-word;
    .value {
      margin-left:4px;
    }
    .customer_service {
      color: #5a29bb;
      cursor: pointer;
    }
  }
}
</style>
